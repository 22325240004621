import Axios from 'axios';
import {LocationQueryValue} from "vue-router";

export const HTTP = Axios.create({
  baseURL: `${process.env.VUE_APP_API_LOCATION}`,
  timeout: process.env.TIMEOUT,
  headers: {
    Accept: 'application/json',
    AccessControlAllowOrigin :'*'
  },
});

export function setAuthorization(token: string | null | LocationQueryValue[]) {
  HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function clearAuthorization() {
  delete HTTP.defaults.headers.common.Authorization;
}
